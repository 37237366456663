// @flow

import path from 'path';
import JSZip from 'jszip';
import find from 'lodash/find';
import first from 'lodash/first';
import has from 'lodash/has';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import type { POWERPOINT, DOCX, DOC, PDF, VIDEO, AUDIO } from '../../typings';

import type { Component } from 'react'
import type {
	Country,
	Document,
} from '../../typings';

type State = {
	isIbr: boolean,
	isDam: boolean,
	loading: boolean,
	loadingPptx: boolean,
	done: boolean,
	warn: boolean | string,
	error: boolean | string,
	download: Blob | null,
	notify: boolean,
	showHelp: boolean,
	deckExists: boolean,
	alert: string | boolean,
	authorized: boolean,
	documentUrl: string | null,
	documentType: POWERPOINT | DOCX | DOC | PDF | VIDEO | AUDIO | null,
	error: string | null,
	imageBlob: Blob | null,
	message: string | null,
	loading: boolean,
	openPdfThumbnailEditor: boolean,
	openVideoThumbnailEditor: boolean,
	openVideoEditor: boolean,
	openLinkEditor: boolean,
	published: boolean,
	uploaded: boolean,
	username: string,
	vaultId: string,
	workerId: string,
	done: boolean,
	download: Blob | null,
	error: string | boolean,
	isIbr: boolean,
	isDam: boolean,
	imageBlob: Blob | null,
	imageScale: {
		scaleX: number,
		scaleY: number,
	},
	loading: boolean,
	loadingDoc: boolean,
	show: boolean,
	test: boolean,
	warn: boolean | string,
	workerId: string,
	worker: Worker,
	mode: 'update' | 'deploy',
	deckExists: boolean,
};

export async function handleResponse(
	this: Component<Props>,
	formData: FormData,
	response
) {
	console.log('response: ', response);

	const error = get(response, 'error', response);

	if (error) {
		const status = get(error, 'status', error);

		if (status === 403) {
			if (formData.get('zip')) {
				const zipBuffer = await formData.get('zip').arrayBuffer();
				const contents = await unzip(zipBuffer);

				const newZip = new File(
					[await zip(formData.get('vaultId'), contents)],
					`${formData.get('vaultId')}.zip`,
					{
						type: formData.get('zip').type,
					}
				);

				formData.set('zip', newZip);

				const retryResponse = await this.props.postForm(formData);
				const retryError = get(retryResponse, 'error', retryResponse);
				const retryStatus = get(retryError, 'status', retryResponse.status);

				if (retryStatus === 200) {
					this.setState(
						{
							loading: false,
							done: true,
						},
						async () => await this.props.fetchContent('PRS')
					);
				} else {
					throw new Error('Error uploading zip, make sure your zip is valid');
				}
			}
		} else if (status === 200) {
			this.setState(
				{
					loading: false,
					done: true,
				},
				async () => await this.props.fetchContent('PRS')
			);
		} else {
			throw new Error(error);
		}
	}
}

export const validateZip = async (content) => {
	try {
		const newZip = new JSZip();
		const zip = await newZip.loadAsync(content);

		return !!zip;
	} catch (err) {
		return false;
	}
};

export const zip = async (vaultId, zipContents) => {
	const newZip = new JSZip();
	zipContents.forEach((file) =>
		newZip.folder(vaultId).file(file.name, file.data)
	);
	const result = await newZip.generateAsync({ type: 'blob' });
	return result;
};

export const unzip = async (zipContents) => {
	const jszip = new JSZip();
	const zipFile = await jszip.loadAsync(zipContents);

	const files = Object.keys(zipFile.files).map(async (key) => {
		const file = zipFile.files[key];
		const png = await zipFile.file(file.name).async('blob');
		return { name: path.basename(file.name), data: png };
	});
	return Promise.all(files);
};

export const removeEmojis = (input) => {
	const withEmojis = /\p{Extended_Pictographic}/u;
	const result = input.replace(withEmojis, '');
	return result;
};

export const stringToArrayBuffer = (str) => new TextEncoder().encode(str);

export const arrayBufferToString = (arrayBuffer) =>
	new TextDecoder().decode(arrayBuffer);

export function base64ArrayBuffer(
	arrayBuffer: ArrayBuffer | string,
	appendPngData: boolean = true
): string {
	let base64: string = '';
	const encodings: string =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

	const bytes: Uint8Array = new Uint8Array(arrayBuffer);
	const byteLength: number = bytes.byteLength;
	const byteRemainder: number = byteLength % 3;
	const mainLength: number = byteLength - byteRemainder;

	let a: number;
	let b: number;
	let c: number;
	let d: number;
	let chunk: number;

	// Main loop deals with bytes in chunks of 3
	for (let i = 0; i < mainLength; i = i + 3) {
		// Combine the three bytes into a single integer
		chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

		// Use bitmasks to extract 6-bit segments from the triplet
		a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
		b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
		c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
		d = chunk & 63; // 63       = 2^6 - 1

		// Convert the raw binary segments to the appropriate ASCII encoding
		base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
	}

	// Deal with the remaining bytes and padding
	if (byteRemainder == 1) {
		chunk = bytes[mainLength];

		a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

		// Set the 4 least significant bits to zero
		b = (chunk & 3) << 4; // 3   = 2^2 - 1

		base64 += encodings[a] + encodings[b] + '==';
	} else if (byteRemainder == 2) {
		chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

		a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
		b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

		// Set the 2 least significant bits to zero
		c = (chunk & 15) << 2; // 15    = 2^4 - 1

		base64 += encodings[a] + encodings[b] + encodings[c] + '=';
	}

	if (appendPngData) {
		return `data:image/png;base64,${base64}`;
	}

	return base64;
}

export function handleError(error: Error): mixed {
	if (has(error, 'error')) {
		return handleError(get(error, 'error'));
	}

	if (has(error, 'type')) {
		const type: ?string = get(error, 'type', '');
		if (type?.includes('ABORT')) {
			return type;
		}
	}

	if (has(error, 'response')) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		// console.error(error.response.data.error);
		// console.error(error.response.status);
		// console.error(error.response.headers);
		const data: mixed = get(error, 'response.data', '');
		if (typeof data === 'string') {
			return data;
		} else if (has(data, 'error')) {
			return get(data, 'error');
		} else {
			return JSON.stringify(data);
		}
	} else if (has(error, 'request')) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the
		// browser and an instance of
		// http.ClientRequest in node.js
		const response: mixed = get(error, 'request.response');

		if (typeof response === 'string') {
			return get(JSON.parse(response), 'error');
		} else {
			return get(response, 'error');
		}
	} else if (has(error, 'message')) {
		// Something happened in setting up the request that triggered an Error
		return get(error, 'message');
	} else {
		return JSON.stringify(error);
	}
}

// NOTE: if US is in the content zone then the language is English (US)
// multiple countries search for US, then its a US content zone otherwise its the first one
export function getLanguage(lang: string): string {
	if (lang === 'English') {
		return 'English (US)';
	}

	return lang;
}

export function getLanguageCode(this: Component<Props>, lang: string): string {
	const { languages } = this.props;

	for (const key in languages) {
		if (languages[key].name === getLanguage(lang)) {
			return languages[key].code.toUpperCase();
		}
	}
	return '';
}

export function checkContentZone(
	this: Component<Props>,
	countryIds: Array<string>,
	region: Array<string>
): boolean {
	const { countries: countryCodes }: { countries: Array<Country> } = this.props;

	return some(countryIds, (countryId) =>
		includes(region, find(countryCodes, { id: countryId })?.abbreviation__vs)
	);
}

export function getContentZone(
	this: Component<Props>,
	countryIds: Array<string>
): string {
	// NOTE: search array for US or global
	// 00C000000000101

	const middleEast: Array<string> = ['UAE', 'EG', 'KW', 'SA', 'IL'];
	const asiaPacific: Array<string> = ['CN', 'TW', 'SG', 'KR', 'IN', 'AU', 'NZ'];
	const us: Array<string> = ['US', 'CA'];
	const lat: Array<string> = ['LAT', 'AR', 'BR', 'MX', 'CL', 'PA'];

	if (checkContentZone.call(this, countryIds, us)) {
		return 'US';
	} else if (checkContentZone.call(this, countryIds, lat)) {
		return 'LAT';
	} else if (find(countryIds, (o) => o === '00C000000000115')) {
		return 'JPN';
	} else if (checkContentZone.call(this, countryIds, middleEast)) {
		return 'ME';
	} else if (checkContentZone.call(this, countryIds, asiaPacific)) {
		return 'APAC';
	} else {
		return 'EU';
	}
}

export function getCountryCode(
	this: Component<Props>,
	countryIds: Array<string>
): Array<string> {
	const { countries: countryCodes }: { countries: Array<Country> } = this.props;

	// NOTE: make this return US if the country code is in latin america

	const dereferencedCodes: Array<string> =
		countryIds.map((countryId: string): string => {
			const item: ?Country = find(countryCodes, { id: countryId });

			if (item) {
				return item.abbreviation__vs;
			} else {
				throw new Error(`Error: Can't dereference country id ${countryId}`);
			}
		}) || [];

	return uniq(dereferencedCodes);
}

export function getKeywords(keywords: string = ''): string {
	return keywords.split(';').join(',');
}

export function dereferenceNewCategory(
	this: Component<Props>,
	category: string
): string {
	const { newCategories } = this.props;

	const dereferencedCategory = find(newCategories, { id: category });
	return get(dereferencedCategory, 'name__v', '');
}

export function getExposure(doc: Document): ?string {
	if (get(doc, 'document_number__v').includes('DAM')) {
		return 'external';
	} else if (
		!isNull(get(doc, 'ace_external_main_category__c', null)) &&
		!isNull(get(doc, 'ace_internal_main_category__c', null))
	) {
		return 'all';
	} else if (isNull(get(doc, 'ace_external_main_category__c', null)) &&
		isNull(get(doc, 'ace_internal_main_category__c', null)) &&
		!isNull(get(doc, 'ace_pod_category__c', null))) {
		// RCL 7/31/23
		// Could possibly also do this when available_locations1__c = ['ACE Pod'], but I'm not sure if it's always the available_locations1__c
		// or possibly just available_locations__c certain scenarios
		return 'ace-pod';
	} else if (isNull(get(doc, 'ace_external_main_category__c', null))) {
		return 'internal';
	} else if (isNull(get(doc, 'ace_internal_main_category__c', null))) {
		return 'external';
	}
}

export function printCategory(
	mainCategory: string,
	secondCategory: ?string,
	thirdCategory: ?string
): string {
	let categoryPath: string = mainCategory;

	if (!isEmpty(secondCategory) && secondCategory !== 'N/A') {
		// $FlowFixMe[incompatible-type]: flow can't tell that 'secondCategory is safe to use
		categoryPath += ` > ${secondCategory}`;
		if (!isEmpty(thirdCategory) && thirdCategory !== 'N/A') {
			// $FlowFixMe[incompatible-type]: again flow can't tell that 'thirdCategory' is safe
			categoryPath += ` > ${thirdCategory}`;
		}
	}
	return categoryPath;
}

export async function getRelatedItems(
	this: Component<Props, State>,
	id: number
): Promise<string> {
	const { dereferenceRelationships } = this.props;
	const { data: relatedItems } = await dereferenceRelationships(id);
	console.log('relatedItems: ', relatedItems);
	return relatedItems.join(',');
}

export async function populateFormWithVaultData(vaultId) {
	const { searchVault, updateForm } = this.props;

	try {
		const { data } = await searchVault(vaultId);

		if (!isEmpty(data.document)) {
			const fileName: string = get(data, 'document.filename__v');
			const id: number = get(data, 'document.id');

			updateForm({
				countryCodes: getCountryCode.call(this, data.document.country__v),
				contentZone: getContentZone.call(this, data.document.country__v),
				languageCode: getLanguageCode.call(
					this,
					first(data.document.language__v)
				),
				aceProDescriptor: get(data, 'document.ace_pro_descriptor__c', ''),
				mainCategory: dereferenceNewCategory.call(
					this,
					get(data, 'document.ace_dam_category__c')
				),
				secondCategory: dereferenceNewCategory.call(
					this,
					get(data, 'document.ace_dam_subcategory__c')
				),
				exposure: getExposure(data.document),
				internalMainCategory: dereferenceNewCategory.call(
					this,
					get(data, 'document.ace_internal_main_category__c')
				),
				internalSecondCategory: dereferenceNewCategory.call(
					this,
					get(data, 'document.ace_internal_secondary_category__c')
				),
				internalThirdCategory: dereferenceNewCategory.call(
					this,
					get(data, 'document.ace_internal_tertiary_category__c')
				),
				externalMainCategory: dereferenceNewCategory.call(
					this,
					get(data, 'document.ace_external_main_category__c')
				),
				externalSecondCategory: dereferenceNewCategory.call(
					this,
					get(data, 'document.ace_external_secondary_category__c')
				),
				externalThirdCategory: dereferenceNewCategory.call(
					this,
					get(data, 'document.ace_external_tertiary_category__c')
				),
				documentId: id,
				relatedItems: await getRelatedItems.call(this, id),
				title: get(data, 'document.name__v', get(data, 'document.title__v')),
				fileName,
				keywords: getKeywords(data.document.keywords__c) || '',
				language: getLanguage(first(data.document.language__v)),
				qpa: (
					data.document.quarterly_plan_of_action__c === 'true' ||
					data.document.quarterly_plan_of_action__c === true
				).toString(),
				wistiaUrl: get(data, 'document.production_wistia_url__c', false),
			});

			const fileType = path.extname(fileName);

			console.log('file type: ', fileType);
			console.log('can tokenize? ', isFunction(this.props.tokenize));

			if (isFunction(this.props.tokenize)) {
				console.log('Tokenizing...');
				const { tokenize } = this.props;
				const fileType = path.extname(fileName);

				if (
					fileType === '.pdf' ||
					fileType === '.docx' ||
					fileType === '.doc' ||
					fileType === '.pptx' ||
					fileType === '.ppt'
				) {
					await tokenize({ id, vaultId, contentType: fileType });
				}
			}

			if (fileType === '.pdf' || fileType === '.docx' || fileType === '.doc') {
				const { fetchPdfThumbnail } = this.props;
				const { data: image } = await fetchPdfThumbnail(vaultId);

				console.log('has thumbnail?: ', image);
				const data = base64ArrayBuffer(image);

				if (image) {
					updateForm('image', data);
					this.setState({ pdfThumbnail: data });
				}
			} else if (fileType === '.wav' || fileType === '.mp3') {
				const { fetchPodcastThumbnail } = this.props;
				const { data: image } = await fetchPodcastThumbnail(id);

				console.log('has thumbnail?: ', image);

				const data = base64ArrayBuffer(image);

				if (image && data !== 'data:image/png;base64,') {
					updateForm('image', data);
					this.setState({ pdfThumbnail: data });
				}
			}

			return data.document;
		} else {
			throw new Error("couldn't find vault document");
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

export async function populatePermissionsAndStatus(docType, vaultId: string) {
	const { updateForm, fetchContent, checkAceDeckIsWideScreen, checkVaultDeckIsWideScreen } = this.props;
	const { isIbr } = this.state;

	try {
		const { data: content } = await fetchContent(vaultId);
		const found = first(content);

		console.log('found: ', found);

		if (found && found.status !== 'not found') {
			const { data: oldDeckIsWideScreen } = await checkAceDeckIsWideScreen(vaultId);
			const { data: newDeckIsWideScreen } = await checkVaultDeckIsWideScreen(vaultId);

			console.log('oldDeckIsWideScreen: ', oldDeckIsWideScreen);
			console.log('newDeckIsWideScreen: ', newDeckIsWideScreen);

			const resizeRects = !oldDeckIsWideScreen && newDeckIsWideScreen;
			updateForm('resizeRects', resizeRects);

			// we want to warn the user if they are updating a deck from 4:3 to 16:9 only
			if (resizeRects) {
				this.setState({ warnAspectRatio: true, disableImageMatching: true });
			} else {
				this.setState({ warnAspectRatio: false });
			}

			const {
				animated = 0,
				permissions = 'pub',
				grouped = false,
				transition = 'none'
			}: {
				animated: 0 | 1,
				permissions: 'pub' | 'IBR' | 'R',
				grouped: boolean,
				transition: string
			} = found;

			updateForm({
				grouped,
				animated: animated === 1,
				status: 'live',
				permissions: isIbr ? 'IBR' : permissions,
				operation: 'update',
				transition
			});

			this.setState({
				mode: 'deploy',
				deckExists: true,
			});
		} else {
			updateForm({
				grouped: false,
				animated: false,
				status: 'live',
				permissions: isIbr ? 'IBR' : 'pub',
				operation: 'create',
				transition: 'none'
			});

			this.setState({
				mode: 'deploy',
				deckExists: false,
			});
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

export function onChange(
	this: Component<Props>,
	{
		currentTarget: { checked, name, type, value, files, selectedOptions },
	}: {
		currentTarget: {
			checked: ?boolean,
			name: string,
			type: string,
			value: mixed,
			files: Array<File>,
			selectedOptions: HTMLCollection<HTMLSelectElement>,
		},
	}
): void {
	const { updateForm } = this.props;

	if (type === 'checkbox') {
		updateForm(name, checked);
	} else if (type === 'select-one') {
		updateForm(name, value);
	} else if (type === 'select-multiple') {
		const values: Array<string> = Array.from(selectedOptions).map(
			(o) => o.value
		);
		updateForm(name, values);
	} else if (files && files.length > 0) {
		updateForm(name, files[0]);
	} else {
		updateForm(name, value);
	}
}
